import React, { useState, useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import Link from 'next/link';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import HeroSection from '@/components/homepage/HeroSection';
import HomepageNewsCard from '@/components/homepage/HomepageNewsCard';
import Footer from '@/components/common/widgets/Footer';
import Layout from '@/components/common/layouts/Layout';
import IEPopup from '@/components/common/IEPopup';
import { useUserContext } from '@/lib/context/useUserContext';
import { useRouter } from 'next/router';
import IntroSection from './homepage/IntroSection';
import BigAndMiniSection from './homepage/BigAndMiniSection';
import HomeActionSection from './homepage/HomeActionSection';
// import OurPartnersSection from './homepage/OurPartnersSection';
// import OurNewsSection from './homepage/OurNewsSection';
import OurPartnersSection from './homepage/OurPartnersSection';
import StepOneSection from './homepage/StepOneSection';
import StepTwoSection from './homepage/StepTwoSection';
// import TestimonialSection from './homepage/TestimonialSection';
import Testimonials from './homepage/testimonials';
import VideoSection from './homepage/VideoSection';
import { getNPosts } from './api/ghost';
import NewsFeatures from './homepage/newsfeatures';
// import PlayButton from './homepage/PlayButton';

function Homepage(props) {
  const { isValid } = useUserContext();
  const router = useRouter();

  if (isValid) {
    window.location.replace('/dashboard/match');
  } else {
    return (
      <Layout>
        <Head>
          <title>Big & Mini: Virtual Volunteering</title>
          <meta name="description" content="Create generation-spanning friendships in minutes. We connect people virtually to combat social isolation and bridge the generation gap." />
        </Head>
        <div>

          <ParallaxProvider>
            <HeroSection
              image="/images/grid.PNG"
              alt="Elder holding ball in his hand"
              title="Bridging Generations to Create Meaningful Connections"
              description="Having someone to talk to makes all the difference."
              otherContent={(
                <div>
                  <a href="https://forms.gle/JcpWdx9rGKjHVysh9" className="hero-button">
                    Get Started
                  </a>
                  {/* <PlayButton/> */}
                </div>
                              )}
            />
          </ParallaxProvider>

          <IntroSection />
          <BigAndMiniSection />
          <VideoSection />
          {/* <Testimonials/> */}

          <StepOneSection />
          <StepTwoSection />

          {/* <TestimonialSection /> */}

          <div className="site-section" style={{ borderTop: '1px solid #EEEEEE' }}>

            <Container fluid="md">
              <Row>
                <h1 className="news-title">Latest News</h1>
              </Row>

              <Row>
                {/* <PostCard
                                    title = {"Hello"}
                                    image = {"https://blog.bigandmini.org/content/images/2020/07/parade.png"}
                                /> */}
                {props.posts ? props.posts.map((post) => (
                  <Link href="/[slug]" as={`/${post.slug}`} legacyBehavior>
                    <HomepageNewsCard
                      title={post.title}
                      tags={post.tags}
                      authors={post.authors}
                      date={post.published_at}
                      image={post.feature_image}
                      description={
                                                        post.excerpt
                                                    }
                      link={`blog/${post.slug}`}
                    />
                  </Link>
                )) : null}
              </Row>
              {/* <Row style = {{
                                float: 'right',
                            marginRight: '20px',
                            textDecoration: 'underline'}}>
                                <a href = "/whatsnew">Read more</a>
                                </Row> */}
            </Container>

          </div>

          {/* <OurPartnersSection /> */}
          <NewsFeatures />
          <HomeActionSection />
          <Footer />
        </div>

        <IEPopup />
      </Layout>
    );
  }
  return null;
}
Homepage.getInitialProps = async () => {
  const posts = await getNPosts(3);
  return { posts };
};
export default Homepage;
